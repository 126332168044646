import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link, useStaticQuery } from 'gatsby'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import React, { useState, useEffect } from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '../components/custom.css'
import '../components/layout.css'
import GalleryCardHomePage from '../components/GalleryCardHomePage'
import Layout from '../components/layout'
import '../components/layout.css'
import SEO from '../components/seo'
import india from '../images/india.jpg'
import twitter from '../images/twitter-icon-png-circle-6.png'
import Firebase from '../components/Firebase'

// import Slider from '../slider/components/Slider'

import Slick from 'react-slick'
import SlickSlider from '../components/SlickSlider'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  whiteColor: {
    color: '#FFFFFF',
  },
  largeFont: {
    fontSize: 54,
    color: '#2196f3',
  },
  overlay: {
    zIndex: 1,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    background: 'rgba(0, 0, 0, 1)',
  },
  marginTop84: {
    marginTop: 84,
  },
  shadow: {
    backgroundColor: '#eed',
    boxShadow: '0 0 15px rgba(0,0,0,0.6)',
    MozBoxShadow: '0 0 15px rgba(0,0,0,0.6)',
    WebkitBoxShadow: '0 0 15px rgba(0,0,0,0.6)',
    OBoxShadow: '0 0 15px rgba(0,0,0,0.6)',
  },
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
}))

const Home = props => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  useEffect(() => {
    setLoading(true)
    Firebase.firestore()
      .collection('tweets')
      .get()
      .then(function(snapshot) {
        setLoading(false)
        setRows([])
        snapshot.forEach(function(doc) {
          setRows(old => [...old, doc.data()])
        })
      })
      .catch(function(error) {
        setLoading(false)
      })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "meeting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      second: file(relativePath: { eq: "office2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      third: file(relativePath: { eq: "india.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <Layout>
        <SEO title="Welcome to IRS Association" />
        {/* <Slider slides={images} autoPlay={3} /> */}
        <SlickSlider />
        <Container>
          <section className={classes.marginTop84}>
            <Typography variant="h4" className={classes.titleFont}>
              Featured Events
            </Typography>

            <Grid
              spacing={2}
              style={{ marginTop: 24 }}
              container
              direction="row"
              alignItems="center"
              justify="center"
              align="center"
            >
              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={1}
                  image={data.first.childImageSharp.fluid}
                  date="31 March 2020"
                  title="119 of The Income Tax Act"
                  desc="Order u/s 119 of The Income Tax Act, 1961 on issue of certificates for lower rate."
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={2}
                  image={data.second.childImageSharp.fluid}
                  date="30 March 2020"
                  title="Additional Charge of PCCIT"
                  desc="Additional Charge of PCCIT Kerala Region to Shri Ravindra Kumar, IRS (86005) -reg.."
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={3}
                  image={data.third.childImageSharp.fluid}
                  date="30 March 2020"
                  title="Postponement of Video conf"
                  desc="Postponement of Video conference on 24th March, 2020 at 4:00 P.M.- reg."
                />
              </Grid>
            </Grid>
          </section>
        </Container>
        <Box
          spacing={8}
          className={classes.marginTop84}
          style={{ backgroundColor: '#efefef' }}
        >
          <Container style={{ padding: 54 }}>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justify="center"
              align="center"
            >
              {rows.map((v, k) => (
                <Grid item md={4} xs={12} key={k}>
                  <Paper
                    raised
                    style={{ padding: 24, maxWidth: 350 }}
                    align="left"
                  >
                    <img
                      src={twitter}
                      width="15%"
                      height="15%"
                      alt="IRS Association"
                    />
                    <Typography
                      style={{ lineHeight: 2, marginTop: 34 }}
                      variant="body1"
                    >
                      {v.tweet.length > 250
                        ? v.tweet.substring(0, 250)
                        : v.tweet}
                      <Button
                        target="_blank"
                        style={{ textTransform: 'none' }}
                        href={`https://twitter.com/${v.author}`}
                        color="primary"
                      >
                        {v.author}
                      </Button>
                    </Typography>
                    <Typography
                      style={{ lineHeight: 2, marginTop: 34 }}
                      variant="body2"
                    >
                      {v.publishedOn}
                      <Button
                        style={{ textTransform: 'none' }}
                        color="primary"
                        href={v.shortLink}
                        target="_blank"
                      >
                        Visit Twitter
                      </Button>
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Container>
          <section className={classes.marginTop84}>
            <Typography variant="h4" className={classes.titleFont}>
              New / Noteworthy
            </Typography>

            <Grid
              align="center"
              spacing={4}
              style={{ marginTop: 24 }}
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  image={india}
                  date="30 March 2020"
                  title="Postponement of Video conf"
                  desc="Postponement of Video conference on 24th March, 2020 at 4:00 P.M.- reg."
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  image={india}
                  date="30 March 2020"
                  title="Postponement of Video conf"
                  desc="Postponement of Video conference on 24th March, 2020 at 4:00 P.M.- reg."
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  image={india}
                  date="30 March 2020"
                  title="Postponement of Video conf"
                  desc="Postponement of Video conference on 24th March, 2020 at 4:00 P.M.- reg."
                />
              </Grid>
            </Grid>
          </section>
        </Container>
        <div
          className={classes.marginTop84}
          style={{ backgroundColor: '#2a4274' }}
        >
          <Container>
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              align="center"
            >
              <Grid item md={12} xs={12} style={{ padding: 54 }}>
                <Typography style={{ color: '#FFF' }} variant="h4">
                  Join IRS Assocation
                </Typography>
              </Grid>
              <Grid item md={7}>
                <Typography
                  variant="body2"
                  style={{ lineHeight: 2, color: '#FFF' }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </Typography>
              </Grid>
              <Grid item md={12} style={{ marginTop: 24, marginBottom: 54 }}>
                <Button
                  component={Link}
                  to="/membership"
                  style={{ color: '#FFF' }}
                  color="primary"
                  variant="outlined"
                >
                  Join Now
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default Home
