import {
  makeStyles,
  Button,
  Typography,
  Icon,
  Hidden,
  useMediaQuery,
  IconButton,
} from '@material-ui/core'
import { Link } from 'gatsby'
import React from 'react'
import Slider from 'react-slick'
import Flag from '../images/medical.jpg'
import ThirdImage from '../images/third.jpg'
import SecondImage from '../images/president.jpg'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import clsx from 'clsx'
import './layout.css'
import './social.css'

const useStyles = makeStyles(theme => ({
  firstSlide: {
    position: 'relative',
    margin: 'auto',
    fontFamily: 'Volkhov',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${Flag})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  secondSlide: {
    position: 'relative',
    margin: 'auto',
    fontFamily: 'Volkhov',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${SecondImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  thirdSlide: {
    position: 'relative',
    margin: 'auto',
    fontFamily: 'Volkhov',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${ThirdImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  bottom: {
    bottom: '5%',
    left: '10%',
    [theme.breakpoints.down('sm')]: {
      bottom: '15%',
      left: '17%',
    },
  },

  font: {
    fontFamily: 'Volkhov',
  },
  title: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    color: '#FFF',
  },
  subTitle: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    marginTop: 24,
    color: '#FFF',
  },
  button: {
    marginTop: 24,
    fontSize: 20,
  },

  iconbar: {
    position: 'absolute',
    top: '50%',
    webkitTransform: 'translateY(-50%)',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
}))

const SlickSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    swipe: true,
    arrows: true,
  }

  const matches = useMediaQuery('(min-width:1024px)')

  const classes = useStyles()
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className={classes.firstSlide}>
            <div
              variant="h2"
              className="slideTitle"
              style={{
                background: 'linear-gradient(90deg,black 0%,transparent 100%)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  align: 'left',
                }}
                className={classes.bottom}
              >
                <Typography
                  variant="h2"
                  align="left"
                  className={classes.title}
                  style={{ float: 'left' }}
                >
                  Coronavirus Update
                </Typography>
                <br />
                <br />
                <br />
                <Typography
                  variant="h6"
                  align="left"
                  style={{ float: 'left', color: '#FFF' }}
                >
                  Indian Revenue Service
                </Typography>
                <br />
                <br />
                <hr
                  style={{
                    float: 'left',
                    width: '10%',
                    height: '5px',
                    background: '#FFF',
                    marginTop: 24,
                  }}
                />
                <br />
                <br />
                <Typography
                  variant="body2"
                  className={classes.subTitle}
                  align="left"
                  style={{ float: 'left', marginTop: 8 }}
                >
                  Since 1924 the foreign affairs community has relied on AFSA –{' '}
                  <br />
                  the voice of the Foreign Service. By joining AFSA, you will be
                  <br />
                  adding to the collective
                  <br />
                </Typography>
                <br />
                <br />
                <Button
                  style={{
                    padding: '20px 70px',
                    align: 'left',
                    float: 'left',
                    backgroundColor: '#FF2B2B',
                    color: '#FFF',
                    textTransform: 'none',
                  }}
                  size="large"
                  component={Link}
                  to="/aboutus"
                  variant="contained"
                  className={classes.button}
                >
                  Read More
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.secondSlide}>
            <div
              variant="h2"
              className="slideTitle"
              style={{
                background: 'linear-gradient(90deg,black 0%,transparent 100%)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  align: 'left',
                }}
                className={classes.bottom}
              >
                <Typography
                  variant="h2"
                  align="left"
                  className={classes.title}
                  style={{ float: 'left' }}
                >
                  Message from the President of IRS
                </Typography>
                <br />
                <br />
                <br />
                <Typography
                  variant="h6"
                  align="left"
                  style={{ float: 'left', color: '#FFF' }}
                >
                  Indian Revenue Service
                </Typography>
                <br />
                <br />
                <hr
                  style={{
                    float: 'left',
                    width: '10%',
                    height: '5px',
                    background: '#FFF',
                    marginTop: 24,
                  }}
                />
                <br />
                <br />
                <Typography
                  variant="body2"
                  className={classes.subTitle}
                  align="left"
                  style={{ float: 'left', marginTop: 8 }}
                >
                  Since 1924 the foreign affairs community has relied on AFSA –{' '}
                  <br />
                  the voice of the Foreign Service. By joining AFSA, you will be
                  <br />
                  adding to the collective
                  <br />
                </Typography>
                <br />
                <br />
                <Button
                  style={{
                    padding: '20px 70px',
                    align: 'left',
                    float: 'left',
                    backgroundColor: '#FF2B2B',
                    color: '#FFF',
                    textTransform: 'none',
                  }}
                  size="large"
                  component={Link}
                  to="/aboutus"
                  variant="contained"
                  className={classes.button}
                >
                  Read More
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.thirdSlide}>
            <div
              variant="h2"
              className="slideTitle"
              style={{
                background: 'linear-gradient(90deg,black 0%,transparent 100%)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  align: 'left',
                }}
                className={classes.bottom}
              >
                <Typography
                  variant="h2"
                  align="left"
                  className={classes.title}
                  style={{ float: 'left' }}
                >
                  Online Master Program
                </Typography>
                <br />
                <br />
                <br />
                <Typography
                  variant="h6"
                  align="left"
                  style={{ float: 'left', color: '#FFF' }}
                >
                  Indian Revenue Service
                </Typography>
                <br />
                <br />
                <hr
                  style={{
                    float: 'left',
                    width: '10%',
                    height: '5px',
                    background: '#FFF',
                    marginTop: 24,
                  }}
                />
                <br />
                <br />
                <Typography
                  variant="body2"
                  className={classes.subTitle}
                  align="left"
                  style={{ float: 'left', marginTop: 8 }}
                >
                  Since 1924 the foreign affairs community has relied on AFSA –{' '}
                  <br />
                  the voice of the Foreign Service. By joining AFSA, you will be
                  <br />
                  adding to the collective
                  <br />
                </Typography>
                <br />
                <br />
                <Button
                  style={{
                    padding: '20px 70px',
                    align: 'left',
                    float: 'left',
                    backgroundColor: '#FF2B2B',
                    color: '#FFF',
                    textTransform: 'none',
                  }}
                  component={Link}
                  to="/aboutus"
                  variant="contained"
                  className={classes.button}
                >
                  Read More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Slider>

      {matches ? (
        <div class="icon-bar">
          <Button
            target="_blank"
            class="facebook button"
            href="https://www.facebook.com/IRSAssociation/"
          >
            <FacebookIcon />
          </Button>

          <Button
            target="_blank"
            class="twitter button"
            href="https://twitter.com/IRSAssociation"
          >
            <TwitterIcon />
          </Button>

          <Button
            target="_blank"
            class="google button"
            href="https://www.instagram.com/irs_association/"
          >
            <InstagramIcon />
          </Button>
        </div>
      ) : (
        <Hidden xsDown mdDown>
          <Button
            target="_blank"
            class="facebook button"
            href="https://www.facebook.com/IRSAssociation/"
          >
            <FacebookIcon />
          </Button>

          <Button
            target="_blank"
            class="twitter button"
            href="https://twitter.com/IRSAssociation"
          >
            <TwitterIcon />
          </Button>

          <Button
            target="_blank"
            class="google button"
            href="https://www.instagram.com/irs_association/"
          >
            <InstagramIcon />
          </Button>
        </Hidden>
      )}
    </div>
  )
}

export default SlickSlider
